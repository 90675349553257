<template>
    <Toast />
    <div
        class="container-fluid"
        @mousedown="setMousePressed(true)"
        @mouseup="setMousePressed(false)"
    >
        <br />
        <div :class="{ headFixed: scrollPosition > 100 }">
            <div style="float: left">
                <h3>Informe ús menjador escolar</h3>
                <h5>
                    {{ informe.centre.nom }} - {{ informe.mesNom }}
                    {{ informe.any }}
                </h5>
                <strong>Secretari/a:</strong> {{ informe.centre.nomSecretari }}
                <br />
                <strong>Preu menjador:</strong>
                {{ informe.centre.importMenjador }}
            </div>

            <div
                style="float: right; vertical-align: bottom; text-align: right"
            >
                <h6>Estat informe: {{ informe.estatText }}</h6>
                <Button
                    type="button"
                    class="btn btn-secondary btn-sm p-text-right"
                    @click="$router.push(returnRoute)"
                    >Tornar</Button
                >&nbsp;
                <Button
                    type="button"
                    class="btn btn-secondary btn-sm p-text-right"
                    @click="saveInforme"
                    >Desar</Button
                >
            </div>

            <div
                style="
                    float: right;
                    vertical-align: bottom;
                    text-align: right;
                    margin-right: 50px;
                    width: 280px;
                "
            >
                <h6>Totals informe:</h6>
                <table style="border: 0px; width: 100%">
                    <tr>
                        <th style="width: 200px">Total 70%:</th>
                        <td>
                            {{
                                parseFloat(informe.sumaImportBeca70)
                                    .toFixed(2)
                                    .toLocaleString()
                            }}€
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 200px">Total 100%:</th>
                        <td>
                            {{
                                parseFloat(informe.sumaImportBeca100)
                                    .toFixed(2)
                                    .toLocaleString()
                            }}€
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 200px">Total compactats:</th>
                        <td>
                            {{
                                parseFloat(informe.sumaImportCompactacio)
                                    .toFixed(2)
                                    .toLocaleString()
                            }}€
                        </td>
                    </tr>
                </table>
                <span v-if="canvisPendents"
                    style="color:red;">
                    <i class="pi pi-exclamation-triangle"></i>
                    Hi ha canvis pendents de desar</span
                >
            </div>
            <div
                style="float: right; vertical-align: bottom; text-align: right"
            >
                <h6>Carrega informe</h6>
                <input
                    type="file"
                    id="file"
                    ref="file"
                    style="display: none"
                    v-on:change="onChangeFileUpload()"
                />
                <Button
                    type="button"
                    class="btn btn-secondary btn-sm p-text-right"
                    @click="$refs.file.click()"
                    >Carrega Excel</Button
                >
            </div>
            <div
                style="
                    float: right;
                    vertical-align: bottom;
                    text-align: right;
                    margin-right: 50px;
                "
            >
                <h6></h6>

                <Button
                    type="button"
                    class="btn btn-secondary btn-sm p-text-right"
                    @click="updateCompactacions()"
                    >Actualitza l'estat</Button
                >
                <div v-if="loadingCompactacions">Carregant...</div>
                <div v-if="loadingError">ERROR</div>
            </div>
        </div>

        &nbsp;

        <table
            class="table table-bordered noselect"
            style="margin-top: 130px"
            @mouseleave="setMousePressed(false)"
        >
            <thead>
                <tr>
                    <th scope="col">IDALU</th>
                    <th scope="col">Nom alumne/a</th>
                    <th scope="col">B</th>
                    <th scope="col">Curs</th>
                    <th scope="col">1</th>
                    <th scope="col">2</th>
                    <th scope="col">3</th>
                    <th scope="col">4</th>
                    <th scope="col">5</th>
                    <th scope="col">6</th>
                    <th scope="col">7</th>
                    <th scope="col">8</th>
                    <th scope="col">9</th>
                    <th scope="col">10</th>
                    <th scope="col">11</th>
                    <th scope="col">12</th>
                    <th scope="col">13</th>
                    <th scope="col">14</th>
                    <th scope="col">15</th>
                    <th scope="col">16</th>
                    <th scope="col">17</th>
                    <th scope="col">18</th>
                    <th scope="col">19</th>
                    <th scope="col">20</th>
                    <th scope="col">21</th>
                    <th scope="col">22</th>
                    <th scope="col">23</th>
                    <th scope="col">24</th>
                    <th scope="col">25</th>
                    <th scope="col">26</th>
                    <th scope="col">27</th>
                    <th
                        scope="col"
                     
                    >
                        28
                    </th>
                    <th
                        scope="col"
                     
                    >
                        29
                    </th>
                    <th
                        scope="col"
                     
                    >
                        30
                    </th>
                    <th
                        scope="col"
                     
                    >
                        31
                    </th>
                    <th>Dies</th>
                    <th>Total</th>
                  <th style="font-size: 12px">Saldo privisional</th>
                  <th style="font-size: 12px">Saldo Restant</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(alumne, index) in informe.alumnes" :key="index">
                    <td scope="row" nowrap class="textSmall">
                        {{ alumne.idalu }}
                    </td>
                    <th scope="row" nowrap>
                        {{ alumne.nom }} {{ alumne.primerCognom }}
                        {{ alumne.segonCognom }}
                    </th>
                    <td>{{ alumne.tipusBeca != 1 ? "100" : "70" }}</td>
                    <td>{{ alumne.cursEscolar }}</td>
                    <td
                        @mousedown="marcarDia(index, 0)"
                        @mouseenter="marcarDiaL(index, 0)"
                        :class="{
                            weekend: alumne.dies[0].isWeekend,
                            marcatNou: alumne.dies[0].marcatNou,
                            compactat: alumne.dies[0].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[0].marcat
                                ? alumne.dies[0].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 1)"
                        @mouseenter="marcarDiaL(index, 1)"
                        :class="{
                            weekend: alumne.dies[1].isWeekend,
                            marcatNou: alumne.dies[1].marcatNou,
                            compactat: alumne.dies[1].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[1].marcat
                                ? alumne.dies[1].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 2)"
                        @mouseenter="marcarDiaL(index, 2)"
                        :class="{
                            weekend: alumne.dies[2].isWeekend,
                            marcatNou: alumne.dies[2].marcatNou,
                            compactat: alumne.dies[2].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[2].marcat
                                ? alumne.dies[2].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 3)"
                        @mouseenter="marcarDiaL(index, 3)"
                        :class="{
                            weekend: alumne.dies[3].isWeekend,
                            marcatNou: alumne.dies[3].marcatNou,
                            compactat: alumne.dies[3].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[3].marcat
                                ? alumne.dies[3].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 4)"
                        @mouseenter="marcarDiaL(index, 4)"
                        :class="{
                            weekend: alumne.dies[4].isWeekend,
                            marcatNou: alumne.dies[4].marcatNou,
                            compactat: alumne.dies[4].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[4].marcat
                                ? alumne.dies[4].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 5)"
                        @mouseenter="marcarDiaL(index, 5)"
                        :class="{
                            weekend: alumne.dies[5].isWeekend,
                            marcatNou: alumne.dies[5].marcatNou,
                            compactat: alumne.dies[5].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[5].marcat
                                ? alumne.dies[5].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 6)"
                        @mouseenter="marcarDiaL(index, 6)"
                        :class="{
                            weekend: alumne.dies[6].isWeekend,
                            marcatNou: alumne.dies[6].marcatNou,
                            compactat: alumne.dies[6].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[6].marcat
                                ? alumne.dies[6].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 7)"
                        @mouseenter="marcarDiaL(index, 7)"
                        :class="{
                            weekend: alumne.dies[7].isWeekend,
                            marcatNou: alumne.dies[7].marcatNou,
                            compactat: alumne.dies[7].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[7].marcat
                                ? alumne.dies[7].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 8)"
                        @mouseenter="marcarDiaL(index, 8)"
                        :class="{
                            weekend: alumne.dies[8].isWeekend,
                            marcatNou: alumne.dies[8].marcatNou,
                            compactat: alumne.dies[8].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[8].marcat
                                ? alumne.dies[8].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 9)"
                        @mouseenter="marcarDiaL(index, 9)"
                        :class="{
                            weekend: alumne.dies[9].isWeekend,
                            marcatNou: alumne.dies[9].marcatNou,
                            compactat: alumne.dies[9].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[9].marcat
                                ? alumne.dies[9].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 10)"
                        @mouseenter="marcarDiaL(index, 10)"
                        :class="{
                            weekend: alumne.dies[10].isWeekend,
                            marcatNou: alumne.dies[10].marcatNou,
                            compactat: alumne.dies[10].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[10].marcat
                                ? alumne.dies[10].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 11)"
                        @mouseenter="marcarDiaL(index, 11)"
                        :class="{
                            weekend: alumne.dies[11].isWeekend,
                            marcatNou: alumne.dies[11].marcatNou,
                            compactat: alumne.dies[11].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[11].marcat
                                ? alumne.dies[11].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 12)"
                        @mouseenter="marcarDiaL(index, 12)"
                        :class="{
                            weekend: alumne.dies[12].isWeekend,
                            marcatNou: alumne.dies[12].marcatNou,
                            compactat: alumne.dies[12].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[12].marcat
                                ? alumne.dies[12].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 13)"
                        @mouseenter="marcarDiaL(index, 13)"
                        :class="{
                            weekend: alumne.dies[13].isWeekend,
                            marcatNou: alumne.dies[13].marcatNou,
                            compactat: alumne.dies[13].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[13].marcat
                                ? alumne.dies[13].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 14)"
                        @mouseenter="marcarDiaL(index, 14)"
                        :class="{
                            weekend: alumne.dies[14].isWeekend,
                            marcatNou: alumne.dies[14].marcatNou,
                            compactat: alumne.dies[14].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[14].marcat
                                ? alumne.dies[14].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 15)"
                        @mouseenter="marcarDiaL(index, 15)"
                        :class="{
                            weekend: alumne.dies[15].isWeekend,
                            marcatNou: alumne.dies[15].marcatNou,
                            compactat: alumne.dies[15].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[15].marcat
                                ? alumne.dies[15].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 16)"
                        @mouseenter="marcarDiaL(index, 16)"
                        :class="{
                            weekend: alumne.dies[16].isWeekend,
                            marcatNou: alumne.dies[16].marcatNou,
                            compactat: alumne.dies[16].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[16].marcat
                                ? alumne.dies[16].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 17)"
                        @mouseenter="marcarDiaL(index, 17)"
                        :class="{
                            weekend: alumne.dies[17].isWeekend,
                            marcatNou: alumne.dies[17].marcatNou,
                            compactat: alumne.dies[17].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[17].marcat
                                ? alumne.dies[17].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 18)"
                        @mouseenter="marcarDiaL(index, 18)"
                        :class="{
                            weekend: alumne.dies[18].isWeekend,
                            marcatNou: alumne.dies[18].marcatNou,
                            compactat: alumne.dies[18].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[18].marcat
                                ? alumne.dies[18].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 19)"
                        @mouseenter="marcarDiaL(index, 19)"
                        :class="{
                            weekend: alumne.dies[19].isWeekend,
                            marcatNou: alumne.dies[19].marcatNou,
                            compactat: alumne.dies[19].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[19].marcat
                                ? alumne.dies[19].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 20)"
                        @mouseenter="marcarDiaL(index, 20)"
                        :class="{
                            weekend: alumne.dies[20].isWeekend,
                            marcatNou: alumne.dies[20].marcatNou,
                            compactat: alumne.dies[20].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[20].marcat
                                ? alumne.dies[20].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 21)"
                        @mouseenter="marcarDiaL(index, 21)"
                        :class="{
                            weekend: alumne.dies[21].isWeekend,
                            marcatNou: alumne.dies[21].marcatNou,
                            compactat: alumne.dies[21].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[21].marcat
                                ? alumne.dies[21].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 22)"
                        @mouseenter="marcarDiaL(index, 22)"
                        :class="{
                            weekend: alumne.dies[22].isWeekend,
                            marcatNou: alumne.dies[22].marcatNou,
                            compactat: alumne.dies[22].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[22].marcat
                                ? alumne.dies[22].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 23)"
                        @mouseenter="marcarDiaL(index, 23)"
                        :class="{
                            weekend: alumne.dies[23].isWeekend,
                            marcatNou: alumne.dies[23].marcatNou,
                            compactat: alumne.dies[23].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[23].marcat
                                ? alumne.dies[23].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 24)"
                        @mouseenter="marcarDiaL(index, 24)"
                        :class="{
                            weekend: alumne.dies[24].isWeekend,
                            marcatNou: alumne.dies[24].marcatNou,
                            compactat: alumne.dies[24].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[24].marcat
                                ? alumne.dies[24].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 25)"
                        @mouseenter="marcarDiaL(index, 25)"
                        :class="{
                            weekend: alumne.dies[25].isWeekend,
                            marcatNou: alumne.dies[25].marcatNou,
                            compactat: alumne.dies[25].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[25].marcat
                                ? alumne.dies[25].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        @mousedown="marcarDia(index, 26)"
                        @mouseenter="marcarDiaL(index, 26)"
                        :class="{
                            weekend: alumne.dies[26].isWeekend,
                            marcatNou: alumne.dies[26].marcatNou,
                            compactat: alumne.dies[26].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[26].marcat
                                ? alumne.dies[26].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        
                        @mousedown="marcarDia(index, 27)"
                        @mouseenter="marcarDiaL(index, 27)"
                        :class="{
                            weekend: alumne.dies[27].isWeekend,
                            marcatNou: alumne.dies[27].marcatNou,
                            compactat: alumne.dies[27].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[27].marcat
                                ? alumne.dies[27].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        
                        @mousedown="marcarDia(index, 28)"
                        @mouseenter="marcarDiaL(index, 28)"
                        :class="{
                            weekend: alumne.dies[28].isWeekend,
                            marcatNou: alumne.dies[28].marcatNou,
                            compactat: alumne.dies[28].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[28].marcat
                                ? alumne.dies[28].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        
                        @mousedown="marcarDia(index, 29)"
                        @mouseenter="marcarDiaL(index, 29)"
                        :class="{
                            weekend: alumne.dies[29].isWeekend,
                            marcatNou: alumne.dies[29].marcatNou,
                            compactat: alumne.dies[29].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[29].marcat
                                ? alumne.dies[29].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td
                        
                        @mousedown="marcarDia(index, 30)"
                        @mouseenter="marcarDiaL(index, 30)"
                        :class="{
                            weekend: alumne.dies[30].isWeekend,
                            marcatNou: alumne.dies[30].marcatNou,
                            compactat: alumne.dies[30].compactat,
                        }"
                        class="dia"
                    >
                        {{
                            alumne.dies[30].marcat
                                ? alumne.dies[30].compactat
                                    ? "C"
                                    : "X"
                                : ""
                        }}
                    </td>
                    <td>{{ alumne.totalDiesMarcats }}</td>
                    <td nowrap>
                        {{
                            parseFloat(alumne.totalImportConsumitMes).toFixed(
                                2
                            )
                        }}€
                    </td>
                  <td nowrap>
                    <span :style="alumne.totalImportRestantProvisional < 0 ? 'color: red;font-weight: bold;':(alumne.totalImportRestantProvisional == 0 ? 'color: orange;font-weight: bold;':'')" v-if="!isNaN(alumne.totalImportRestantProvisional)">{{parseFloat(alumne.totalImportRestantProvisional).toFixed(
                        2
                    )}}€</span>
                    <span v-else>-</span>
                  </td>
                    <td nowrap :style="alumne.totalImportRestant < 0 ? 'color: red;font-weight: bold;':(alumne.totalImportRestant == 0 ? 'color: orange;font-weight: bold;':'')">
                       {{
                            parseFloat(alumne.totalImportRestant).toFixed(
                                2
                            )
                        }}€
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import InformeService from "@/services/informes.service.js";
//import FileUpload from "vue-upload-component";
import { ref } from "vue";
export default {
    name: "User",
    components: {
        //  FileUpload,
    },
    data() {
        return {
            content: "",
            centre: { id: 0, nom: "Carregant", preuMenjador: 0, preuBeca: 0 },
            scrollPosition: null,
            canvisPendents: false,
            loadingCompactacions: false,
            returnRoute: "/informes",
            informe: {
                id: 1,
                any: 2022,
                mes: 10,
                mesNom: "Octubre",
                idCurs: 9,
                centre: { id: 3, nom: "Escola de prova" },

                preuMenjador100: 6.54,
                preuMenjador70: 4.43,
                sumaDiesBeca70: 0,
                sumaDiesBeca100: 0,
                sumaImportBeca70: 0,
                sumaImportBeca100: 0,
                sumaDiesCompactacio: 0,
                sumaImportCompactacio: 0,
                alumnes: [],
                numAlumnes: 0,
            },
            mousePressed: false,
        };
    },
    setup() {
        const upload = ref(null);

        const files = ref();
        function inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (
                    /(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)
                ) {
                    return prevent();
                }

                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent();
                }
            }
        }
        function inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // add
                console.log("add", newFile);
            }
            if (newFile && oldFile) {
                // update
                console.log("update", newFile);
            }
            if (!newFile && oldFile) {
                // remove
                console.log("remove", oldFile);
            }
        }
        return {
            files,
            upload,
            inputFilter,
            inputFile,
        };
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },

        marcarDia: function (indexAlumne, diaArray) {
          const rounded = Math.round(this.informe.alumnes[indexAlumne].totalImportRestant*100)/100
            if(this.informe.alumnes[indexAlumne].dies[diaArray].marcat == 0 && rounded < this.informe.alumnes[indexAlumne].preuBeca){
                this.$toast.add({ severity: 'error', summary: "No hi ha una quantitat restant suficient!", life: 3000 });
                return;
            }


            if (!this.informe.alumnes[indexAlumne].dies[diaArray].isWeekend) {
                this.canvisPendents = true;
                let marcat =
                    this.informe.alumnes[indexAlumne].dies[diaArray].marcat ==
                    1;
                this.informe.alumnes[indexAlumne].dies[diaArray].marcat = marcat
                    ? 0
                    : 1;
                this.informe.alumnes[indexAlumne].dies[diaArray].marcatNou = 1;
                let countDies = 0;
                let countImport = 0;
                this.informe.alumnes[indexAlumne].dies.forEach((dia) => {
                    if (dia.marcat) {
                        countDies++;
                        if (
                            this.informe.alumnes[indexAlumne].preceptiu ||
                            this.informe.alumnes[indexAlumne].tipusBeca == 2 ||
                            this.informe.alumnes[indexAlumne].tipusBeca == 22 ||
                            this.informe.alumnes[indexAlumne].tipusBeca == 23
                        ) {
                            countImport += parseFloat(
                                this.informe.centre.preuBeca
                            );
                        } else {
                            countImport += dia.compactat
                                ? parseFloat(this.informe.centre.preuBeca)
                                : parseFloat(this.informe.centre.preuBeca) *
                                  0.7;
                        }
                    }
                });
                this.informe.alumnes[indexAlumne].totalDiesMarcats = countDies;
                this.informe.alumnes[indexAlumne].totalImportConsumitMes =
                    Math.round(countImport * 100) / 100;
                    this.informe.alumnes[indexAlumne].totalImportConsumitMes
                const {totalImportDisponible,totalImportConsumitMesosAnteriors,totalImportConsumitMes,totalImportConsumitMesosAnteriorsEnviats} = this.informe.alumnes[indexAlumne]
                this.informe.alumnes[indexAlumne].totalImportRestant = totalImportDisponible- (totalImportConsumitMesosAnteriors+totalImportConsumitMes)
              if(!isNaN(totalImportConsumitMesosAnteriorsEnviats)){
                this.informe.alumnes[indexAlumne].totalImportRestantProvisional = totalImportDisponible- (totalImportConsumitMesosAnteriorsEnviats+totalImportConsumitMes)
              }

            }
        },
        setMousePressed(flag) {
            this.mousePressed = flag;
        },
        marcarDiaL: function (indexAlumne, diaArray) {
            if (this.mousePressed) {
                if (
                    !this.informe.alumnes[indexAlumne].dies[diaArray].isWeekend
                ) {
                    this.canvisPendents = true;
                    let marcat =
                        this.informe.alumnes[indexAlumne].dies[diaArray]
                            .marcat == 1;
                    this.informe.alumnes[indexAlumne].dies[diaArray].marcat =
                        marcat ? 1 : 1;
                    this.informe.alumnes[indexAlumne].dies[
                        diaArray
                    ].marcatNou = 1;
                    let countDies = 0;
                    let countImport = 0;
                    this.informe.alumnes[indexAlumne].dies.forEach((dia) => {
                        if (dia.marcat) {
                            countDies++;
                            if (
                                this.informe.alumnes[indexAlumne].preceptiu ||
                                this.informe.alumnes[indexAlumne].tipusBeca == 2 ||
                                this.informe.alumnes[indexAlumne].tipusBeca == 22 ||
                                this.informe.alumnes[indexAlumne].tipusBeca == 23
                            ) {
                                countImport += parseFloat(
                                    this.informe.centre.preuBeca
                                );
                            } else {
                                countImport += dia.compactat
                                    ? parseFloat(this.informe.centre.preuBeca)
                                    : parseFloat(this.informe.centre.preuBeca) *
                                      0.7;
                            }
                        }
                    });
                    this.informe.alumnes[indexAlumne].totalDiesMarcats =
                        countDies;
                    this.informe.alumnes[indexAlumne].totalImportConsumitMes =
                        Math.round(countImport * 100) / 100;
                    const {totalImportDisponible,totalImportConsumitMesosAnteriors,totalImportConsumitMes,totalImportConsumitMesosAnteriorsEnviats} = this.informe.alumnes[indexAlumne]
                    this.informe.alumnes[indexAlumne].totalImportRestant = Math.round((totalImportDisponible- (totalImportConsumitMesosAnteriors+totalImportConsumitMes))*100)/100
                  if(!isNaN(totalImportConsumitMesosAnteriorsEnviats)){
                    this.informe.alumnes[indexAlumne].totalImportRestantProvisional = Math.round((totalImportDisponible- (totalImportConsumitMesosAnteriorsEnviats+totalImportConsumitMes))*100)/100
                  }
                }
            }
        },
        getInforme() {
            let that = this;
            InformeService.getInforme(this.$route.params.id).then(
                (result) => {
                  console.log('result',result)
                    this.returnRoute = "/informes"
                    let user = JSON.parse(localStorage.getItem("user"));
                    if(user.user.empresa_menjador_id){
                      this.returnRoute+= `/${result.data.informe.centre.id}`
                    }
                  that.informe = result.data.informe;
                    that.informe.alumnes = result.data.alumnes;
                    this.informe.sumaDiesBeca70 = 0;
                    this.informe.sumaDiesBeca100 = 0;
                    this.informe.sumaImportBeca70 = 0;
                    this.informe.sumaImportBeca100 = 0;
                    this.informe.sumaDiesCompactacio = 0;
                    this.informe.sumaImportCompactacio = 0;
                    this.informe.sumaDiesPreceptius = 0;
                    this.informe.alumnes.forEach((alumne) => {
                        alumne.dies.forEach((dia) => {
                            dia.marcatNou = 0;
                        });
                    });
                    //that.reCalc();
                    this.informe.alumnes.forEach((alumne) => {
                        alumne.dies.forEach((dia) => {
                            if (dia.marcat) {
                                if (
                                    alumne.preceptiu ||
                                    alumne.tipusBeca == 2 ||
                                    alumne.tipusBeca == 22 ||
                                    alumne.tipusBeca == 23
                                ) {
                                    this.informe.sumaImportBeca100 +=
                                        parseFloat(
                                            this.informe.centre.preuBeca
                                        );
                                    this.informe.sumaDiesBeca100++;
                                } else {
                                    if (dia.compactat) {
                                        this.informe.sumaImportCompactacio +=
                                            parseFloat(
                                                this.informe.centre.preuBeca
                                            );
                                        this.informe.sumaDiesCompactacio++;
                                    } else {
                                        this.informe.sumaImportBeca70 +=
                                            parseFloat(
                                                this.informe.centre.preuBeca
                                            ) * 0.7;
                                        this.informe.sumaDiesBeca70++;
                                    }
                                }
                            }
                        });
                    });
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        saveInforme() {
            this.informe.sumaDiesBeca70 = 0;
            this.informe.sumaDiesBeca100 = 0;
            this.informe.sumaImportBeca70 = 0;
            this.informe.sumaImportBeca100 = 0;
            this.informe.sumaDiesCompactacio = 0;
            this.informe.sumaImportCompactacio = 0;
            this.informe.sumaDiesPreceptius = 0;
            this.informe.numAlumnes = this.informe.alumnes.length;
            // this.reCalc();
            this.informe.alumnes.forEach((alumne) => {
                alumne.dies.forEach((dia) => {
                    if (dia.marcat) {
                        if (
                            alumne.preceptiu ||
                            alumne.tipusBeca == 2 ||
                            alumne.tipusBeca == 22 ||
                            alumne.tipusBeca == 23
                        ) {
                            this.informe.sumaImportBeca100 += parseFloat(
                                this.informe.centre.preuBeca
                            );
                            this.informe.sumaDiesBeca100++;
                        } else {
                            if (dia.compactat) {
                                this.informe.sumaImportCompactacio +=
                                    parseFloat(this.informe.centre.preuBeca);
                                this.informe.sumaDiesCompactacio++;
                            } else {
                                this.informe.sumaImportBeca70 +=
                                    parseFloat(this.informe.centre.preuBeca) *
                                    0.7;
                                this.informe.sumaDiesBeca70++;
                            }
                        }
                    }
                });
            });
            let that = this;
            InformeService.saveInforme(this.informe).then(
                (result) => {
                    console.log(result);
                    this.informe.alumnes.forEach((alumne) => {
                        alumne.dies.forEach((dia) => {
                            dia.marcatNou = 0;
                        });
                    });
                    that.canvisPendents = false;
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        fillInforme() {
            let that = this;
            let informeSend = this.informe;
            this.informe = [];
            InformeService.fillNouInforme(informeSend).then(
                (response) => {
                    that.informe = response.data.informe;
                    that.informe.alumnes = response.data.alumnes;
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        reCalc() {
            this.informe.alumnes.forEach((alumne) => {
                let countDies = 0;
                let countImport = 0;
                alumne.dies.forEach((dia) => {
                    if (dia.marcat) {
                        countDies++;
                        if (
                            alumne.preceptiu ||
                            alumne.tipusBeca == 2 ||
                            alumne.tipusBeca == 22 ||
                            alumne.tipusBeca == 23
                        ) {
                            countImport += parseFloat(
                                this.informe.centre.preuBeca
                            );
                        } else {
                            countImport += dia.compactat
                                ? parseFloat(this.informe.centre.preuBeca)
                                : parseFloat(this.informe.centre.preuBeca) *
                                  0.7;
                        }
                    }
                });
                alumne.totalDiesMarcats = countDies;
                alumne.totalImportConsumitMes =
                    Math.round(countImport * 100) / 100;
                const {totalImportDisponible,totalImportConsumitMesosAnteriors,totalImportConsumitMes,totalImportConsumitMesosAnteriorsEnviats} = alumne
                alumne.totalImportRestant = Math.round((totalImportDisponible- (totalImportConsumitMesosAnteriors+totalImportConsumitMes))*100)/100
              if(!isNaN(totalImportConsumitMesosAnteriorsEnviats)){
                alumne.totalImportRestantProvisional = Math.round((totalImportDisponible- (totalImportConsumitMesosAnteriorsEnviats+totalImportConsumitMes))*100)/100
              }
            });
        },
        getInformeParsed() {
            let that = this;
            InformeService.getInformeParsed(1).then(
                (response) => {
                    response.data.alumnes.forEach(function (alumneInforme) {
                        let aluFound = that.informe.alumnes.find(
                            (el) => el.idalu == alumneInforme.idalu
                        );
                        if (aluFound != null) {
                            aluFound.dies.forEach((dia, idx) => {
                                if (!dia.isWeekend) {
                                    dia.marcat = alumneInforme.dies[idx].marcat;
                                    dia.marcatNou = true;
                                }
                            });
                        }
                    });
                    that.reCalc();
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        daysInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        },
        submitForm() {},

        onChangeFileUpload() {
            this.file = this.$refs.file.files[0];
            let formData = new FormData();
            let that = this;
            formData.append("file", this.file);
            InformeService.uploadFile(formData).then(
                (response) => {
                    response.data.alumnes.forEach(function (alumneInforme) {
                        let aluFound = that.informe.alumnes.find(
                            (el) => el.idalu == alumneInforme.idalu
                        );
                        if (aluFound != null) {
                            aluFound.dies.forEach((dia, idx) => {
                                if (!dia.isWeekend) {
                                    dia.marcat = alumneInforme.dies[idx].marcat;
                                    dia.marcatNou = true;
                                }
                            });
                        }
                    });
                    that.reCalc();
                },
                () => {
                    alert("Hi ha hagut un error");
                }
            );
            this.$refs.file.value = null;
        },
        updateCompactacions() {
            let that = this;
            let oldInforme = this.informe;
            this.loadingCompactacions = true;
            this.loadingError = false;
            InformeService.fillNouInforme(this.informe).then(
                (result) => {
                    this.loadingCompactacions = false;
                    that.informe = result.data.informe;
                    that.informe.alumnes = result.data.alumnes;
                    this.informe.sumaDiesBeca70 = 0;
                    this.informe.sumaDiesBeca100 = 0;
                    this.informe.sumaImportBeca70 = 0;
                    this.informe.sumaImportBeca100 = 0;
                    this.informe.sumaDiesCompactacio = 0;
                    this.informe.sumaImportCompactacio = 0;
                    this.informe.sumaDiesPreceptius = 0;
                    this.informe.alumnes.forEach((alumne) => {
                        alumne.dies.forEach((dia) => {
                            dia.marcatNou = 0;
                        });
                    });
                    try {
                        this.informe.alumnes.forEach((alumne) => {
                            let aluFound;
                            if (alumne.idalu !== null) {
                                aluFound = oldInforme.alumnes.find(
                                    (el) =>
                                        el.idalu?.trim() == alumne.idalu?.trim()
                                );
                            }

                            if (aluFound == null) {
                                console.log(alumne);
                            } else {
                                alumne.dies.forEach((dia, index) => {
                                    dia.marcat = aluFound.dies[index].marcat;

                                    if (dia.marcat) {
                                        if (
                                            alumne.preceptiu ||
                                            alumne.tipusBeca == 2 ||
                                            alumne.tipusBeca == 22 ||
                                            alumne.tipusBeca == 23
                                        ) {
                                            this.informe.sumaImportBeca100 +=
                                                parseFloat(
                                                    this.informe.centre.preuBeca
                                                );
                                            this.informe.sumaDiesBeca100++;
                                        } else {
                                            if (dia.compactat) {
                                                this.informe.sumaImportCompactacio +=
                                                    parseFloat(
                                                        this.informe.centre
                                                            .preuBeca
                                                    );
                                                this.informe
                                                    .sumaDiesCompactacio++;
                                            } else {
                                                this.informe.sumaImportBeca70 +=
                                                    parseFloat(
                                                        this.informe.centre
                                                            .preuBeca
                                                    ) * 0.7;
                                                this.informe.sumaDiesBeca70++;
                                            }
                                        }
                                    }
                                });
                            }

                            that.reCalc();
                        });
                    } catch (error) {
                        that.loadingError = true;
                        console.log(error);
                    }
                },
                (error) => {
                    this.loadingCompactacions = false;
                    that.loadingError = true;
                    console.log(error);
                }
            );
        },
    },
    computed: {},
    mounted() {
        this.getInforme();
        let that = this;
        let user = JSON.parse(localStorage.getItem("user"));
        InformeService.getCentre(user.user.id_centre,this.$route.params.id).then(
            (response) => {
                that.centre = response.data.centre;
                //that.centre.preuBeca = that.centre.importMenjador > 6.54 ? 6.54 : that.centre.preuMenjador;
            },
            (error) => {
                console.log(error);
            }
        );
        window.addEventListener("scroll", this.updateScroll);
    },
    destroy() {
        window.removeEventListener("scroll", this.updateScroll);
    },
};
</script>
<style scoped>
.weekend {
    background-color: #ddd !important;
}
.compactat {
    background-color: rgba(234, 238, 248, 0.678);
}
.dia {
    text-align: center;
}
.dia:hover {
    background-color: rgb(215, 221, 233);
    cursor: pointer;
}
table {
    font-size: 14px;
}
tr:hover {
    background-color: #fafafa !important;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.marcatNou {
    font-weight: bolder !important;
}
html:not([data-scroll="0"]) .headFixed {
    width: 100%;
    position: fixed !important;
    top: 0px;
    background-color: #fff;
    padding-right: 60px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}
.textSmall{
    font-size: 10px;

    padding: 14px 5px 12px 5px;
}
</style>